<script>
import TeachersService from '@/service/teachers.service';
import DisciplinesService from '@/service/disciplines.service';
import axios from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'modal-register-teacher',

  components: {
    FormAvatarUpload: () => import('@/components/FormAvatarUpload'),
  },

  data() {
    return {
      dialog: false,
      loading: false,
      loadingSave: false,
      disciplinieItems: [],
      form: { address: {}, customer: {}, disciplines: null },
      masked: {
        cpf: '',
        phone: '',
        address: {
          cep: '',
        },
      },
    };
  },

  created() {
    this.$on('create', this.create);
    this.$on('edit', this.edit);
  },

  methods: {
    create() {
      this.clearForm();
      this.fetchDisciplines();
      this.dialog = true;
    },

    clearForm() {
      this.form = { address: {}, customer: {} };
    },

    onMaska(val, field) {
      set(this.form, field, val);
    },

    edit(id) {
      this.fetch(id);
      this.fetchDisciplines();
      this.dialog = true;
    },

    async findAddressByCep(event) {
      if (event.target.value) {
        const res = await axios.get(`https://brasilapi.com.br/api/cep/v2/${event.target.value}`);
        this.form.address = res?.data;
      } else {
        this.form.address = {};
      }
    },

    async fetchDisciplines() {
      try {
        const disciplinieItems = await DisciplinesService.find();
        this.disciplinieItems = disciplinieItems.data;
      } catch (e) {
        console.log(e);
      }
    },

    async fetch(id) {
      this.loading = true;

      try {
        const teacher = await TeachersService.get(id);
        this.form = cloneDeep(teacher);
        if (teacher.address) {
          this.masked.address = cloneDeep(teacher.address);
        } else {
          this.form.address = {};
        }

        this.form.disciplines = this.form.disciplines.map((d) => d.id);
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    async save() {
      this.loadingSave = true;
      try {
        const form = cloneDeep(this.form);
        form.avatarId = this.$refs?.avatar?.avatarId;
        form.customer.avatarId = form.avatarId;
        form.customer.name = form.name;
        form.customer.cpf = form.cpf;
        if (form.id) {
          const id = this.form.id;
          delete form.id;
          await TeachersService.update(id, form);
        } else {
          await TeachersService.create(form);
        }
        this.dialog = false;
        this.$swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Salvo com sucesso!',
          timer: 2000,
          showConfirmButton: false,
        });
        this.$emit('success');
      } catch (e) {
        console.error(e);
      }
      this.loadingSave = false;
    },

    handleCancel() {
      this.dialog = false;
      this.clearForm();
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="1400px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="text-h5">{{ !form.id ? 'Cadastro' : 'Edição' }} de professor</span>
      </v-card-title>
      <v-card-text>
        <v-tabs background-color="transparent" centered light>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#data"> Dados </v-tab>
          <v-tab-item class="tab-bg" value="data">
            <v-container class="container-tab">
              <v-row>
                <v-col cols="12" sm="12">
                  <v-row>
                    <v-col>
                      <div class="text-center">
                        <FormAvatarUpload v-if="dialog" ref="avatar" :value="form.avatar" tenant />
                      </div>
                    </v-col>
                    <v-col cols="9" sm="9">
                      <v-row>
                        <v-col v-if="form.id" sm="12" lg="2">
                          <v-text-field
                            v-model="form.id"
                            label="ID"
                            color="white"
                            hide-details="auto"
                            disabled
                            outlined
                            dense
                          />
                        </v-col>
                        <v-col sm="12" lg="3">
                          <v-text-field
                            v-model="form.cpf"
                            v-mask="'###.###.###-##'"
                            :value-raw="masked.cpf"
                            :value="masked.cpf"
                            label="CPF"
                            color="white"
                            hide-details="auto"
                            outlined
                            persistent-placeholder
                            dense
                            @rawValue="onMaska($event, 'cpf')"
                          />
                        </v-col>
                        <v-col sm="12" lg="9">
                          <v-text-field
                            v-model="form.name"
                            label="Nome completo"
                            color="white"
                            hide-details="auto"
                            outlined
                            persistent-placeholder
                            dense
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <v-text-field
                            v-model="form.birthDate"
                            label="Data de Nascimento"
                            color="white"
                            hide-details="auto"
                            type="date"
                            outlined
                            persistent-placeholder
                            dense
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-select
                            v-model="form.gender"
                            :items="[
                              { text: 'Masculino', value: 'M' },
                              { text: 'Feminino', value: 'F' },
                            ]"
                            label="Sexo"
                            color="white"
                            hide-details="auto"
                            outlined
                            persistent-placeholder
                            dense
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="form.nationality"
                            label="Nacionalidade"
                            color="white"
                            hide-details="auto"
                            outlined
                            persistent-placeholder
                            dense
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="form.naturalness"
                            label="Naturalidade"
                            color="white"
                            hide-details="auto"
                            outlined
                            persistent-placeholder
                            dense
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="9">
                          <v-autocomplete
                            v-model="form.disciplines"
                            :items="disciplinieItems"
                            item-text="name"
                            item-value="id"
                            outlined
                            clearable
                            deletable-chips
                            multiple
                            dense
                            small-chips
                            chips
                            color="white"
                            label="Disciplina"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                          <v-select
                            v-model="form.session"
                            :items="[
                              { text: 'Matutino', value: 'MORNING' },
                              { text: 'Vespertino', value: 'EVENING' },
                              { text: 'Noturno', value: 'NOCTURNAL' },
                              { text: 'Integral', value: 'INTEGRAL' },
                            ]"
                            label="Período"
                            color="white"
                            hide-details="auto"
                            outlined
                            persistent-placeholder
                            dense
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field
                            v-model="form.phone"
                            v-mask="'(##) #####-####'"
                            :value-raw="masked.phone"
                            :value="masked.phone"
                            label="Celular"
                            color="white"
                            hide-details="auto"
                            outlined
                            persistent-placeholder
                            dense
                            @rawValue="onMaska($event, 'phone')"
                          />
                        </v-col>
                        <v-col cols="8">
                          <v-text-field
                            v-model="form.email"
                            label="Email"
                            color="white"
                            hide-details="auto"
                            outlined
                            persistent-placeholder
                            dense
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-subheader>
                    <v-row>
                      <span>Endereço</span>
                    </v-row>
                  </v-subheader>
                  <v-row>
                    <v-col cols="2">
                      <v-text-field
                        v-model="form.address.cep"
                        v-mask="'#####-###'"
                        :value="masked.address.cep"
                        :value-raw="masked.address.cep"
                        label="CEP"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                        @blur="findAddressByCep"
                        @rawValue="onMaska($event, 'address.cep')"
                      />
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-model="form.address.street"
                        label="Rua"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="form.address.number"
                        label="Número"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="5">
                      <v-text-field
                        v-model="form.address.neighborhood"
                        label="Bairro"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="form.address.city"
                        label="Cidade"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="form.address.state"
                        label="UF"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="form.annotation"
                        label="Anotações"
                        color="white"
                        hide-details="auto"
                        auto-grow
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab href="#access"> Acesso </v-tab>
          <v-tab-item value="access">
            <v-container class="container-tab">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="form.customer.access"
                        :items="[
                          { text: 'Permitir', value: true },
                          { text: 'Bloquear', value: false },
                        ]"
                        label="Acesso"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.customer.startTime"
                        label="Hora início"
                        type="time"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.customer.endTime"
                        label="Hora fim"
                        type="time"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="form.customer.freeAccess"
                        color="white"
                        label="Este usuário possui acesso livre"
                        hide-details="auto"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleCancel"> Cancelar </v-btn>
        <v-btn color="success" :loading="loadingSave" @click="save"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.v-tabs-items {
  background-color: transparent !important;
}

.container-tab {
  min-height: 600px;
}
</style>
